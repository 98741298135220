import React from 'react';
import '../styles/Home.css'; // Import the CSS file

function Home() {
  return (
    <div className="container text-center mt-5">
      <div className="jumbotron">
        <h1 className="display-4">Software Engineering Class SFSU</h1>
        <p className="lead section-text">Section 04</p>
        <hr className="my-4" />
        <p className="team-text">Team 02</p>
      </div>
    </div>
  );
}

export default Home;
